<script lang="ts">
import { useAppAbility } from '@/permissions/useAppAbility'
import { useAuthentication } from '@prionect/ui'
import { defineComponent } from 'vue'
import CenteredPage from '@/layouts/pages/CenteredPage.vue'
import { useProjectStore } from '@/stores/project'
import { useUserStore } from '@/stores/user'
import { Project } from '@/model'
import { ElNotification } from 'element-plus'
import { project } from '@/config/fields'
import ProjectHSBLibParameterFieldRow from '@/components/project/ProjectHSBLibParameterFieldRow.vue'
import { useProject } from '@/composables/useProject'
import { FieldConfigHsbLibParameters } from '@/config/fields/project'

enum ProjectTabs {
  general = 'general',
  hsb_lib_parameters = 'hsb_lib_parameters'
}

export default defineComponent({
  name: 'BaseView',
  components: { ProjectHSBLibParameterFieldRow, CenteredPage },

  data: () => ({
    ProjectTabs,
    activeTab: ProjectTabs.general,
    fieldConfig: project,
    fieldConfigHsbLibParameters: FieldConfigHsbLibParameters,
    saving: false
  }),

  setup() {
    const { can } = useAppAbility()
    const { currentUser } = useAuthentication()
    const projectStore = useProjectStore()
    const userStore = useUserStore()
    const { projectId } = useProject()

    projectStore.ensureLoaded()
    userStore.loadDisplayNames()

    return { can, currentUser, projectStore, userStore, projectId }
  },

  mounted() {
    const nameInput: HTMLInputElement = this.$el.querySelector('[data-field="name"] input')
    nameInput?.focus()
  },

  computed: {
    create(): boolean {
      return !!this.$route.meta.create
    },
    project(): Partial<Project> | undefined {
      return this.projectStore.findById(this.projectId) ?? this.draft()
    }
  },

  methods: {
    draft(): Project {
      return {
        name: '',
        mediaType: 'telecom',
        crs: 'EPSG:3857',
        users: [this.currentUser.id],
        hsblibParameter: {
          corridorFlatend: null,
          corridorFlatstart: null,
          corridorMode: null,
          loggingTraceback: null,
          logLevel: null,
          flagInnerImpedanceBesselFunction: null,
          pipelineSegmentation: null,
          relAbstol: null,
          relAngletol: null,
          relMaxAngle: null,
          relMinSegmentLength: null,
          relSimplifyMedium: null,
          trimTillDecimalPoints: null,
          utmReimportTolerance: null,
          utmTrimMode: null
        }
      } as Project
    },
    async save(values: Project, submissionContext: any) {
      // Check whether the user removed himself
      const isAdmin = this.can('manage', 'all')
      const userIsAssignedToProject = values.users.includes(this.currentUser.id)
      if (!isAdmin && !userIsAssignedToProject) {
        submissionContext.setFieldError(
          'users',
          'Sie müssen sich selbst dem Projekt zuordnen, um zu speichern.'
        )
        return
      }

      this.saving = true
      try {
        const savedProject = await this.projectStore.save(values)
        if (this.create) {
          await this.$router.push({
            name: 'project-edit',
            params: { projectId: savedProject.id }
          })
          ElNotification.success('Das Projekt wurde angelegt. Sie können es nun konfigurieren')
        } else {
          ElNotification.success('Das Projekt wurde erfolgreich gespeichert.')
        }
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <CenteredPage :title="create ? 'Neues Projekt' : 'Projektdaten'" max-width="80rem">
    <div v-if="project" class="max-w-[45rem] mx-auto">
      <p-form
        :cancelable="create"
        :item="project"
        :saving="saving"
        @cancel="$router.back()"
        @submit="save"
      >
        <el-tabs v-model="activeTab">
          <!-- Allgemein -->
          <el-tab-pane :name="ProjectTabs.general" label="Allgemein">
            <p-field v-bind="fieldConfig.name" />
            <p-field v-bind="fieldConfig.customer" />
            <p-field :filterable="false" v-bind="fieldConfig.mediaType" />
            <p-field :filterable="false" v-bind="fieldConfig.crs" />
            <p-field v-bind="{ ...fieldConfig.users, items: userStore.userItems }" />
          </el-tab-pane>

          <!-- HSBLib-Parameters -->
          <el-tab-pane :name="ProjectTabs.hsb_lib_parameters" label="HSBLib-Parameter">
            <div class="table-simple table-simple--striped overflow-auto">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th class="w-20">HSBLib-Standard benutzen</th>
                    <th style="max-width: 150px">Benutzerdefinierter Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <ProjectHSBLibParameterFieldRow
                    v-for="(field, index) in fieldConfigHsbLibParameters"
                    :key="index"
                    :field-config="field"
                  />
                </tbody>
              </table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </p-form>
    </div>
  </CenteredPage>
</template>

<style lang="css" scoped>
:deep([data-field='name'] input) {
  font-weight: 600 !important;
}
</style>
