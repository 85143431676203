<script lang="ts">
import ImportFromProjectStep from '@/components/overhead-line/import-from-other-project/ImportFromProjectStep.vue'
import SelectOverheadLinesStep from '@/components/overhead-line/import-from-other-project/SelectOverheadLinesStep.vue'
import SelectProjectStep from '@/components/overhead-line/import-from-other-project/SelectProjectStep.vue'
import { OverheadLineId, ProjectId } from '@/model'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OverheadLineImportFromProjectDialog',
  components: { ImportFromProjectStep, SelectOverheadLinesStep, SelectProjectStep },

  data: () => ({
    importing: false,
    selectedOverheadLines: [] as OverheadLineId[],
    selectedProject: undefined as ProjectId | undefined
  }),

  computed: {
    steps() {
      return [
        {
          id: 'selectProject',
          title: 'Schritt 1/3: Projekt auswählen',
          nextButtonDisabled: !this.selectedProject
        },
        {
          id: 'selectOverheadLines',
          title: 'Schritt 2/3: Freileitung(en) auswählen',
          nextButtonLabel: 'Import starten',
          nextButtonDisabled: this.selectedOverheadLines.length < 1
        },
        {
          id: 'import',
          title: 'Schritt 3/3: Import durchführen',
          nextButtonLabel: 'Schließen',
          nextButtonDisabled: this.importing,
          prevButtonLabel: this.importing ? 'Abbrechen' : 'zurück'
        }
      ]
    }
  }
})
</script>

<template>
  <p-wizard :steps="steps" class="!w-[80rem]">
    <template #step:selectProject>
      <SelectProjectStep v-model="selectedProject" />
    </template>
    <template #step:selectOverheadLines>
      <SelectOverheadLinesStep v-model="selectedOverheadLines" :project="selectedProject!" />
    </template>
    <template #step:import>
      <ImportFromProjectStep
        v-model:importing="importing"
        :overhead-lines="selectedOverheadLines"
        :project="selectedProject!"
      />
    </template>
  </p-wizard>
</template>

<style scoped lang="css"></style>
