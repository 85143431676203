import { FieldConfig } from '@prionect/ui/dist/types/components/form/types'
import { MediaGroup } from '@/model'

const fields: Record<
  keyof Omit<MediaGroup, 'id' | 'mediaGeometry' | 'project'> | 'pipeType' | 'rhoE',
  FieldConfig
> = {
  name: {
    name: 'name',
    label: 'Name',
    required: true
  },
  type: {
    name: 'type',
    label: 'Typ',
    type: 'select',
    items: []
  },
  pipeType: {
    name: 'pipeType',
    label: 'Rohrleitungstyp',
    type: 'select',
    items: [],
    required: true
  },
  height: {
    name: 'height',
    label: 'Höhe / Tiefe',
    type: 'number',
    step: 1,
    unit: 'm',
    required: true
  },
  rhoE: {
    name: 'rhoE',
    label: 'Spezifischer Widerstand Erde ρE',
    type: 'number',
    min: 0,
    unit: 'Ωm',
    required: true
  }
}

export default fields
