<script lang="ts">
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { ConductorAllocation, ProjectId } from '@/model'
import ConductorAllocationForm from './ConductorAllocationForm.vue'
import { ElNotification } from 'element-plus'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'ConductorAllocationDetail',
  components: { ConductorAllocationForm, DetailPanel },

  setup() {
    const store = useConductorAllocationStore()
    return { store }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    create(): boolean {
      return !this.$route.params['id']
    },

    projectId(): ProjectId {
      return this.$route.params[RouteParams.ProjectId] as string
    },

    id() {
      return this.$route.params.id as string
    },

    selectedItems(): ConductorAllocation[] {
      if (this.store.loaded && !this.create) {
        const el = this.store.findById(this.id)
        if (el) {
          return [el]
        }
      }
      return []
    },

    title(): string {
      if (this.create) {
        return 'Neue Leiterzuordnung'
      } else {
        return 'Leiterzuordnung bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.$router.push({ name: 'project-conductor-allocations' })
    },

    async save(item: ConductorAllocation) {
      this.saving = true
      try {
        await this.store.save({ ...item, project: this.projectId })

        ElNotification.success(
          this.create
            ? 'Leiterzuordnung wurde erfolgreich angelegt.'
            : 'Daten wurden erfolgreich gespeichert.'
        )
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" @close="onClose">
    <ConductorAllocationForm :saving="saving" cancelable @cancel="onClose" @submit="save" />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
