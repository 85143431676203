<script lang="ts">
import { MaptilerCoordinatesApi } from '@/api'
import { StyleLike } from 'ol/style/Style'
import { sourceStyle } from './style/source'
import { GeoJSON } from 'geojson'

import { Feature, Map, View } from 'ol'
import OlGeoJSON from 'ol/format/GeoJSON.js'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'

import 'ol/ol.css'
import { OSM } from 'ol/source'
import VectorSource from 'ol/source/Vector'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    geojson: {
      type: Object as PropType<GeoJSON>
    },
    crs: {
      type: String,
      default: 'EPSG:3857'
    },
    mapStyle: {
      type: [Object, Array, Function] as PropType<StyleLike>,
      default: () => sourceStyle
    }
  },

  watch: {
    geojson() {
      this.renderMap()
    },
    crs() {
      this.renderMap()
    }
  },

  setup() {
    const map = new Map({ target: undefined })
    return { map }
  },

  mounted() {
    this.renderMap()
  },

  methods: {
    async renderMap() {
      this.map.dispose()
      this.map = new Map({ target: this.$refs.mapRef as HTMLDivElement })

      // Setup map projection
      await MaptilerCoordinatesApi.loadProjection(this.crs)

      const view = new View({
        constrainResolution: true
      })
      this.map.setView(view)

      const osmLayer = new TileLayer({
        source: new OSM(),
        opacity: 0.6
      })
      this.map.addLayer(osmLayer)

      if (this.geojson) {
        const overlaySource = new VectorSource({
          features: new OlGeoJSON({
            dataProjection: this.crs,
            featureProjection: view.getProjection()
          }).readFeatures(this.geojson) as Feature[]
        })

        const overlayLayer = new VectorLayer({
          source: overlaySource,
          style: this.mapStyle
        })
        this.map.addLayer(overlayLayer)
        view.fit(overlaySource.getExtent(), { padding: [32, 32, 32, 32] })
      }
    }
  }
})
</script>

<template>
  <div ref="mapRef" class="border rounded-lg overflow-hidden"></div>
</template>

<style scoped lang="css"></style>
