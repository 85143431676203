<script lang="ts">
import { HsbApi } from '@/api'
import { useProject } from '@/composables/useProject'
import { OverheadLineId } from '@/model'
import { HsbLibObjectEnum } from '@/model/calculation'
import { useCalculationStore } from '@/stores/calculation'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { triggerDownload } from '@/util/helpers'
import { ProjectMediaType } from '@gridside/hsb-api'
import { defineComponent } from 'vue'

const CalculationApi = HsbApi.calculation

export default defineComponent({
  name: 'ExportTab',

  data: () => ({
    loadingOverheadLine: false,
    loadingMeshedMediumSimulation: false
  }),

  setup() {
    const { project, projectId } = useProject()
    return {
      project,
      projectId,
      calculationStore: useCalculationStore(),
      overheadLineStore: useOverheadLineStore()
    }
  },

  computed: {
    operationStates() {
      return this.calculationStore.current?.studyCase?.operationStates || []
    },

    overheadLineItems() {
      return this.overheadLineStore.items.map((item) => ({
        value: item.overheadLine.id,
        label: item.overheadLine.name
      }))
    },

    subResultItems(): Array<{ value?: number; label: string }> {
      const subresults =
        this.calculationStore.current?.studyCase?.operationStates.map((opState, index) => ({
          value: index,
          label: `Teilergebnis Betriebszustand ${index + 1}`
        })) || []
      if (this.project?.mediaType === ProjectMediaType.PIPE) {
        return [{ value: undefined, label: 'Gesamtergebnis ' }, ...subresults]
      } else {
        return subresults
      }
    }
  },

  methods: {
    async downloadMeshedMediumSimulation() {
      this.loadingMeshedMediumSimulation = true
      try {
        await this.downloadResults(HsbLibObjectEnum.MESHED_MEDIUM_SIMULATION)
      } finally {
        this.loadingMeshedMediumSimulation = false
      }
    },

    async downloadOverheadLine(overheadLineId: OverheadLineId) {
      this.loadingOverheadLine = true
      try {
        await this.downloadResults(HsbLibObjectEnum.OVERHEADLINE, overheadLineId)
      } finally {
        this.loadingOverheadLine = false
      }
    },

    async downloadResults(type: HsbLibObjectEnum, overheadLineId?: OverheadLineId) {
      const calculationId = this.calculationStore.current?.id
      if (!calculationId) {
        return
      }

      const { raw } = await CalculationApi.getCalculationHsbLibObjectRaw({
        projectId: this.projectId,
        calculationId,
        hsblibObject: type,
        overheadLineId
      })

      const isMeshedMedia = type === HsbLibObjectEnum.MESHED_MEDIUM_SIMULATION
      const response = isMeshedMedia ? await raw.blob() : await raw.text()

      const filename = `${type}_${
        isMeshedMedia ? this.projectId : overheadLineId
      }_${Intl.DateTimeFormat('de', {
        dateStyle: 'short'
      })
        .format(new Date())
        .replaceAll('.', '_')}`

      triggerDownload(response, filename, isMeshedMedia ? 'application/zip' : 'application/json')
    }
  }
})
</script>

<template>
  <div>
    <p-form-section title="HSBlib-Berechnungsdaten">
      <!-- Meshed Medium Simulation -->
      <el-button
        class="mr-4"
        :loading="loadingMeshedMediumSimulation"
        @click="downloadMeshedMediumSimulation"
      >
        <el-icon class="el-icon--left !mr-2" size="16"><DownloadIcon /></el-icon>
        MeshedMediumSimulation
      </el-button>

      <!-- Overhead line OHL -->
      <el-dropdown>
        <el-button class="w-48" :loading="loadingOverheadLine">
          OverheadLine
          <el-icon class="el-icon--right"><ChevronDownIcon /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu class="min-w-48">
            <el-dropdown-item
              v-for="item in overheadLineItems"
              :key="item.value"
              @click="downloadOverheadLine(item.value)"
            >
              <el-icon class="el-icon--left pr-2" size="24"><DownloadIcon /></el-icon>
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </p-form-section>
  </div>
</template>

<style scoped lang="css"></style>
