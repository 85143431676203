import knownProjections from '@/config/projections'
import { getEnv } from '@/util'
import { register } from 'ol/proj/proj4'
import proj4 from 'proj4'
import { HttpClient } from '@/api/useHttpClient'
import { WebSocket } from '@/api/useWebSocket'

export type ProjectionSpec = {
  name?: string
  // Projection definition in WKT ("well known text") format
  proj4: string
  bbox: [number, number, number, number]
}
export type ProjectionSpecs = Record<string, ProjectionSpec>

/**
 * Fetches GIS projection data for coordinate reference system from Maptiler Coordinates API
 */
export class MaptilerCoordinatesApiClass {
  constructor(public httpClient: HttpClient, public webSocket: WebSocket) {}

  async findProjectionData(code: string): Promise<ProjectionSpec> {
    // If we have data for this projection, use them
    if (knownProjections[code]) {
      return knownProjections[code]
    }

    // otherwise, fetch from Maptiler Coordinates API
    const maptilerApiKey = getEnv('VITE_MAPTILER_API_KEY')
    const response = await fetch(
      `https://api.maptiler.com/coordinates/search/${code}.json?exports=true&key=${maptilerApiKey}`
    )
    const data = await response.json()

    if (data.total === 0) {
      throw new Error('Could not find EPSG projection data for CRS code "' + code + '".')
    } else if (data.total > 1) {
      throw new Error(
        'Could not find EPSG projection data. The CRS code "' + code + '" is ambiguous.'
      )
    }
    const result: any = data.results[0]
    const spec = {
      name: result.name,
      proj4: result.exports.proj4,
      wkt: result.exports.wkt,
      bbox: result.bbox
    }

    // Cache projection data
    knownProjections[code] = spec
    return spec
  }

  async loadProjection(projectionCode: string) {
    const epsgData = await this.findProjectionData(projectionCode)

    // Update proj4 database if, and only if, this is a unknown projection
    // (updating anyways for EPSG:3857 led to displacing side effects)
    if (!proj4.defs(projectionCode)) {
      proj4.defs(projectionCode, epsgData.proj4)
      register(proj4)
    }
  }
}
