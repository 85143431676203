import fields, { fieldsConductorState } from '@/config/fields/study-case'
import { CalculationModes, SuperpositionPreset } from '@/model'
import { parser } from '@/util/codemirror/formula'
import { OperationState, StudyCase, StudyCaseType } from '@gridside/hsb-api'
import { z } from 'zod'

const OperationStateSchemaBase = z.object({
  conductorStates: z
    .object({
      current: z.number().min(fieldsConductorState.current.min, 'Mindestens eine Phase'),
      angle: z.number().min(fieldsConductorState.angle.min).max(fieldsConductorState.angle.max)
    })
    .array(),
  system: z.string().uuid()
})

export const AutoFailItemSchema = z.object({
  system: z.string().uuid(),
  current: z.number().nonnegative()
})
export const AutoAWEItemSchema = z.object({
  system: z.string().uuid(),
  current: z.number(),
  angle: z.number()
})
export const ManualItemSchema = OperationStateSchemaBase.extend({
  mirrored: z.boolean().optional()
})

export const StudyCaseConfigBase = z
  .object({
    modeSelected: z.nativeEnum(CalculationModes),
    superpositionPreset: z.nativeEnum(SuperpositionPreset)
  })
  .passthrough()

export const ConfigRules = z.discriminatedUnion('modeSelected', [
  z.object({
    modeSelected: z.literal(CalculationModes.MANUAL),
    manual: ManualItemSchema.array().min(
      1,
      'Mindestens ein manueller Betriebszustand muss definiert sein.'
    )
  }),
  z.object({
    modeSelected: z.literal(CalculationModes.AUTO_AWE),
    autoAWE: AutoAWEItemSchema.array().min(
      1,
      'Für den AWE-Fall muss mindestens ein System ausgewählt sein.'
    )
  }),
  z.object({
    modeSelected: z.literal(CalculationModes.AUTO_FAIL),
    autoFail: AutoFailItemSchema.array().min(
      1,
      'Für den Fehlerfall muss mindestens ein System ausgewählt sein.'
    )
  })
])

export const StudyCaseConfigSchema = StudyCaseConfigBase.and(ConfigRules)
export type StudyCaseConfig = z.infer<typeof StudyCaseConfigSchema>

export const StudyCaseSchemaBase = z.object({
  id: z.string().uuid(),
  name: z.string().min(fields.name.min, fields.name.required),
  description: z.string().nullish(),
  configuration: z.any().optional(),
  operationStates: OperationStateSchemaBase.array(),
  limitVoltage: z.number().min(fields.limitVoltage.min),
  expectationFactor: z.number().min(fields.expectationFactor.min).max(fields.expectationFactor.max),
  project: z.string(),
  superposition: z.string().superRefine((value, ctx) => {
    // Use Lezer parse to check errors in syntax
    const tree = parser.parse(value)
    let syntaxErrors = 0
    tree.iterate({
      enter(node): boolean | void {
        if (node.type.isError) {
          syntaxErrors++
        }
      }
    })
    if (syntaxErrors > 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Überlagerung: ${syntaxErrors} Syntaxfehler`
      })
    }
  }),
  type: z.nativeEnum(StudyCaseType)
})

/**
 * Type-checking between: API-Client type <=> Zod-schema type
 */
export const StudyCaseSchema: z.ZodType<StudyCase> = StudyCaseSchemaBase
export const OperationStateSchema: z.ZodType<OperationState> = OperationStateSchemaBase

export type AutoFailConfig = z.infer<typeof AutoFailItemSchema>
export type AutoAWEConfig = z.infer<typeof AutoAWEItemSchema>
export type ManualConfig = z.infer<typeof ManualItemSchema>
