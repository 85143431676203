<script lang="ts">
import OverheadLineImportFromProjectDialog from '@/components/overhead-line/import-from-other-project/OverheadLineImportFromProjectDialog.vue'
import { useRouter } from 'vue-router'

import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'OverheadLineImportFromProject',
  components: { OverheadLineImportFromProjectDialog },
  setup() {
    const showDialog = ref(true)
    const router = useRouter()

    // wait for close animation
    const onClose = () => {
      showDialog.value = false
      setTimeout(() => {
        router.push({ name: 'overhead-lines-index' })
      }, 200)
    }
    return {
      showDialog,
      onClose
    }
  }
})
</script>

<template>
  <OverheadLineImportFromProjectDialog v-bind="$attrs" v-model:show="showDialog" @close="onClose" />
</template>
