<script lang="ts">
import { HsbApi } from '@/api'
import { useProject } from '@/composables/useProject'
import {
  ConductorAllocationId,
  ConductorTypeId,
  OverheadLineId,
  ProjectId,
  SystemId,
  TowerTypeId
} from '@/model'
import { useConductorAllocationStore } from '@/stores/conductor-allocation'
import { useConductorTypeStore } from '@/stores/conductor-type'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { useSystemStore } from '@/stores/system'
import { useTowerTypeStore } from '@/stores/tower-type'
import { findUniqueCopyName } from '@/util/helpers'
import {
  ConductorAllocation,
  ConductorType,
  OverheadLineExport,
  ProjectExportData,
  System,
  TowerRequest,
  TowerType
} from '@gridside/hsb-api'
import { v4 } from 'uuid'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ImportFromProjectStep',
  props: {
    importing: {
      type: Boolean,
      default: false
    },
    /** Overhead line ids to import */
    overheadLines: {
      type: Array as PropType<OverheadLineId[]>,
      default: () => []
    },
    /** Project to import from */
    project: {
      type: String as PropType<ProjectId>,
      required: true
    }
  },

  data: () => ({
    currentOverheadLine: undefined as OverheadLineExport | undefined,
    error: false as false | string,
    importData: undefined as ProjectExportData | undefined,
    mappedIds: {} as Record<string, string>,
    progress: 0,
    progressStepsPerOverheadLine: 7,
    progressTotal: 100,
    progressText: 'bitte warten',
    success: false
  }),

  setup() {
    return {
      conductorAllocationStore: useConductorAllocationStore(),
      conductorTypeStore: useConductorTypeStore(),
      currentProject: useProject().project,
      overheadLineStore: useOverheadLineStore(),
      systemStore: useSystemStore(),
      towerTypeStore: useTowerTypeStore()
    }
  },

  mounted() {
    this.doImport()
  },

  methods: {
    async doImport() {
      this.$emit('update:importing', true)
      this.progress = 0
      this.progressTotal = 5 + this.overheadLines.length
      this.mappedIds = {}

      await this.loadProjectData()

      try {
        if (!this.importData) {
          throw new Error('Projektdaten konnten nicht geladen werden')
        }

        // Load dependent project entities (might be shared between overhead lines)
        await this.importProjectDependencies()

        // Now import the actual overhead lines
        for (const overheadLineId of this.overheadLines) {
          this.currentOverheadLine = this.importData.overheadLine?.find(
            (item) => item.id === overheadLineId
          )
          await this.importOverheadLine(overheadLineId)
          this.progress++
        }

        this.success = true
        this.progress = 100
        this.progressText = 'Import abgeschlossen.'
        this.$emit('update:importing', false)
      } catch (e: any) {
        this.error = e || e.message
      }
    },

    /**
     * Import all entities the overhead line(s) depend on
     */
    async importProjectDependencies() {
      const usedConductorAllocationIds = {} as Record<ConductorAllocationId, true>
      const usedConductorTypeIds = {} as Record<ConductorTypeId, true>
      const usedSystemIds = {} as Record<SystemId, true>
      const usedTowerTypeIds = {} as Record<TowerTypeId, true>
      const mapId = this.mapId

      if (!this.currentProject) {
        throw new Error('Project to import to was not set.')
      }

      this.importData?.overheadLine?.forEach((overheadLine) => {
        // only find dependencies for the overhead lines to be imported, skip other
        if (!this.overheadLines.includes(overheadLine.id)) {
          return
        }

        const towers = overheadLine.data?.tower || []

        // find tower dependencies (tower types, conductor types, conductor allocations)
        towers.forEach((tower) => {
          ;[tower.in, tower.out].forEach((towerPart) => {
            if (towerPart?.type) {
              usedTowerTypeIds[towerPart.type] = true
            }
            if (towerPart?.allocation) {
              usedConductorAllocationIds[towerPart.allocation] = true
            }
            if (towerPart?.earthwires) {
              towerPart.earthwires.forEach((earthWireConductor) => {
                usedConductorTypeIds[earthWireConductor] = true
              })
            }
          })
        })
      })

      // find conductor allocation dependencies (systems)
      const conductorAllocations = Object.keys(usedConductorAllocationIds).map(
        (allocationId) =>
          this.importData?.conductorAllocation?.find(
            (allocation) => allocation.id === allocationId
          )!
      ) as ConductorAllocation[]

      conductorAllocations.forEach((allocation) => {
        allocation.mapping.forEach((mapping) => {
          if (mapping.system) {
            usedSystemIds[mapping.system] = true
          }
        })
      })

      // find system dependencies (conductor types)
      const systems: System[] =
        this.importData?.system?.filter((system) => usedSystemIds[system.id]) || []
      systems.forEach((system) => {
        system.conductorTypes.forEach((conductorType) => {
          if (conductorType) {
            usedConductorTypeIds[conductorType] = true
          }
        })
      })

      // duplicate tower types
      this.progressText = 'Masttypen importieren'
      const towerTypes = Object.keys(usedTowerTypeIds)
        .map((id) => this.importData?.towerType?.find((item) => item.id === id))
        .filter((item) => item !== undefined) as TowerType[]

      for (const towerType of towerTypes) {
        await this.towerTypeStore.save({
          ...towerType,
          id: mapId(towerType.id),
          project: this.currentProject!.id,
          name: findUniqueCopyName(
            towerType.name,
            this.towerTypeStore.projectItems.map((item) => item.name)
          )
        })
      }
      this.progress++

      // duplicate conductor types
      this.progressText = 'Leitertypen importieren'
      const conductorTypes = Object.keys(usedConductorTypeIds)
        .map((id) => this.importData?.conductorType?.find((item) => item.id === id))
        .filter((item) => item !== undefined) as ConductorType[]

      for (const conductorType of conductorTypes) {
        await this.conductorTypeStore.save({
          ...conductorType,
          id: mapId(conductorType.id),
          project: this.currentProject!.id,
          name: findUniqueCopyName(
            conductorType.name,
            this.conductorTypeStore.projectItems.map((item) => item.name)
          )
        })
      }
      this.progress++

      // duplicate systems
      this.progressText = 'Systeme importieren'
      for (const system of systems) {
        await this.systemStore.save({
          ...system,
          id: mapId(system.id),
          project: this.currentProject!.id,
          name: findUniqueCopyName(
            system.name,
            this.systemStore.items.map((item) => item.name)
          ),
          conductorTypes: system.conductorTypes.map((id) => mapId(id))
        })
      }
      this.progress++

      // duplicate conductor allocations
      this.progressText = 'Leiterzuordnungen importieren'
      for (const conductorAllocation of conductorAllocations) {
        await this.conductorAllocationStore.save({
          ...conductorAllocation,
          id: mapId(conductorAllocation.id),
          project: this.currentProject!.id,
          name: findUniqueCopyName(
            conductorAllocation.name,
            this.conductorAllocationStore.items.map((item) => item.name)
          ),
          mapping: conductorAllocation.mapping.map((item) => ({
            ...item,
            system: item.system ? mapId(item.system) : item.system
          }))
        })
      }
      this.progress++
    },

    /**
     * Import an actual overhead line including towers and spans into the current project
     */
    async importOverheadLine(id: OverheadLineId) {
      const mapId = this.mapId
      const overheadLine = this.importData?.overheadLine?.find((item) => item.id === id)
      if (!overheadLine) {
        return
      }

      // remove import data
      const slimOverheadLine = { ...overheadLine }
      delete slimOverheadLine['data']

      this.progressText = `Freileitung "${overheadLine.name}" importieren`
      await this.overheadLineStore.save({
        ...slimOverheadLine,
        id: mapId(slimOverheadLine.id),
        project: this.currentProject!.id,
        name: findUniqueCopyName(
          slimOverheadLine.name,
          this.overheadLineStore.items.map((item) => item.overheadLine.name)
        )
      })
      this.progress++

      this.progressText = `Freileitung "${overheadLine.name}": Masten und Spannfelder importieren`

      const convertedTowers: TowerRequest[] =
        overheadLine.data?.tower?.map((tower, index) => {
          const convertedTower: TowerRequest = {
            ...tower,
            id: mapId(tower.id),
            position: (tower.position || 0) + 1,
            overheadLine: mapId(overheadLine.id),
            in: {
              ...tower.in,
              allocation: mapId(tower.in.allocation),
              earthwires: tower.in.earthwires.map((id) => mapId(id)),
              type: mapId(tower.in.type)
            },
            relatedTowers: []
          }
          if (tower.out) {
            convertedTower.out = {
              ...tower.out,
              allocation: mapId(tower.out.allocation),
              earthwires: tower.out.earthwires.map((id) => mapId(id)),
              type: mapId(tower.out.type)
            }
          }

          const span = overheadLine.data?.span ? overheadLine.data.span[index] : undefined
          if (span) {
            convertedTower.span = {
              ...span,
              id: mapId(span.id),
              beginTower: mapId(span.beginTower),
              endTower: mapId(span.endTower),
              overheadLine: mapId(span.overheadLine)
            }
          }
          return convertedTower
        }) || []
      await this.overheadLineStore.towersReplaceAll({ list: convertedTowers }, mapId(id))
    },

    /**
     * Load project export data to get the overhead lines and all their dependencies
     */
    async loadProjectData() {
      this.progressText = 'Projektdaten laden'
      this.importData = (await HsbApi.projects.exportProject(this.project)).data
      this.progress++
    },

    /**
     * Utility function to map original UUIDs to newly generated
     * @param oldId
     */
    mapId(oldId: string) {
      if (!this.mappedIds[oldId]) {
        this.mappedIds[oldId] = v4()
      }
      return this.mappedIds[oldId]
    }
  }
})
</script>

<template>
  <div v-if="success" class="flex flex-col items-center h-48 justify-center">
    <p-success-icon class="text-success-500 w-20 mb-4" />
    <p class="text-success-600 text-lg">Der Import war erfolgreich.</p>
  </div>

  <div v-else>
    <h2 class="text-center text-xl mb-8">
      <span v-if="error" class="text-error-500">Der Import ist fehlgeschlagen.</span>
      <span v-else>{{ $t('overheadLine.importFromProject.importProgressTitle') }}...</span>
    </h2>
    <div>
      <el-progress
        :percentage="Math.min(Math.ceil((progress / progressTotal) * 100), 100)"
        :show-text="false"
        :stroke-width="16"
        :status="error ? 'exception' : 'success'"
        class="font-bold"
      />
    </div>
    <div v-if="error" class="mt-4 mb-8 text-sm font-semibold text-error-500 text-center">
      {{ error }}
    </div>
    <div v-else class="mt-4 mb-8 text-center text-sm text-gray-400">
      <span v-if="progressText.trim() !== ''">
        {{ progressText + '...' }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="css"></style>
