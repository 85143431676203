<script lang="ts">
import SystemTag from '@/components/system/SystemTag.vue'
import { studyCase as fieldConfig } from '@/config/fields'
import { StudyCaseWithConfig, SuperpositionPreset } from '@/model'
import { useSystemStore } from '@/stores/system'
import { ExtensionCommonViewPlugin } from '@/util/codemirror/ExtensionCommonViewPlugin'
import { createOperationStatesAutocompletion } from '@/util/codemirror/ExtensionOperationStatesAutocomplete'
import { createOperationStateHover } from '@/util/codemirror/ExtensionOperationStatesHover'
import { createOperationStatesViewPlugin } from '@/util/codemirror/ExtensionOperationStatesViewPlugin'
import { parser } from '@/util/codemirror/formula'
import { FormulaSyntaxLinter } from '@/util/codemirror/FormulaSyntaxLinter'
import {
  operationStateAtomic,
  operationStateLabel,
  operationStateToString
} from '@/util/codemirror/helper'
import { LanguageSupport, LRLanguage } from '@codemirror/language'
import { lintGutter } from '@codemirror/lint'
import { EditorState, type Extension } from '@codemirror/state'
import { EditorView } from '@codemirror/view'
import { OperationState } from '@gridside/hsb-api'
import { styleTags, tags as t } from '@lezer/highlight'
import { DropdownOption } from '@util/element-plus'
import { watchDeep } from '@vueuse/core'
import { ElNotification } from 'element-plus'
import { FormContext, useField } from 'vee-validate'
import {
  computed,
  ComputedRef,
  defineComponent,
  inject,
  PropType,
  ref,
  shallowRef,
  watch
} from 'vue'
import { Codemirror } from 'vue-codemirror'
import { generateSuperpositionPresetExpression } from './util'

export default defineComponent({
  components: {
    SystemTag,
    Codemirror
  },
  name: 'StudyCaseSuperpositionForm',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    operationStates: {
      type: Array as PropType<OperationState[]>,
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup() {
    const superpositionField = useField('superposition')
    const systemStore = useSystemStore()

    const form = inject('studyCaseForm') as FormContext<StudyCaseWithConfig>
    const operationStatesDraft = inject('operationStatesDraft') as ComputedRef<OperationState[]>

    // Erstellen Sie eine Sprache mit dem Lezer-Parser
    const parserWithMetadata = parser.configure({
      props: [
        styleTags({
          Number: t.number,
          Variable: t.variableName,
          FunctionCall: t.function(t.strong),
          '( )': t.paren
        })
      ]
    })
    const formulaLanguage = LRLanguage.define({ parser: parserWithMetadata })
    const formulaLanguageSupport = new LanguageSupport(formulaLanguage, lintGutter())

    /**
     * Use ":key" to re-render codemirror.
     * Reason is that when operation states are changing we need to update the AtomicRanges inside the editor
     *
     * Maybe this is somehow possible with more knowledge...
     */
    const key = ref(0)
    watchDeep(operationStatesDraft, () => key.value++)

    const extensions = computed<Extension[]>(() => {
      const extensions: Extension[] = [formulaLanguage]
      extensions.push(createOperationStateHover(/\w/, operationStatesDraft.value))
      extensions.push(createOperationStatesAutocompletion(operationStatesDraft.value))
      extensions.push(createOperationStatesViewPlugin(operationStatesDraft.value))
      extensions.push(ExtensionCommonViewPlugin)
      extensions.push(formulaLanguageSupport)
      extensions.push(FormulaSyntaxLinter)
      extensions.push(EditorView.lineWrapping)

      return extensions
    })

    const editorView = shallowRef<EditorView>()
    const container = shallowRef<HTMLDivElement>()
    const handleReady = (payload: {
      view: EditorView
      state: EditorState
      container: HTMLDivElement
    }) => {
      editorView.value = payload.view
      container.value = payload.container
    }

    watch(operationStatesDraft, (value: any) => {
      const preset = form.values.configuration.superpositionPreset
      if (preset !== SuperpositionPreset.CUSTOM) {
        form.setFieldValue('superposition', generateSuperpositionPresetExpression(preset, value))
      }
    })

    /**
     * Helper to always get valid EditorView and EditorState
     */
    function withStateAndView(callback: (view: EditorView, state: EditorState) => any) {
      if (!editorView.value) {
        ElNotification.warning({
          message: 'Fehlgeschlagen: Editor-Instanz nicht vorhanden',
          showClose: true,
          duration: 15000
        })
        return
      }
      callback(editorView.value, editorView.value.state)
    }

    function insertAtCursorPosition(insert: string) {
      withStateAndView((editorView, editorState) => {
        const transaction = editorState.update({
          changes: {
            from: editorState.selection.main.head,
            insert
          },
          selection: {
            anchor: editorState.selection.main.head + insert.length // put cursor after inserted text
          }
        })
        editorView.dispatch(transaction)
      })
    }

    function systemName(system: string) {
      return systemStore.systemName(system)
    }

    return {
      extensions,
      fieldConfig,
      key,
      form,
      handleReady,
      insertAtCursorPosition,
      operationStatesDraft,
      superpositionField,
      systemName
    }
  },
  methods: {
    onEditorChange(value: string) {
      this.form.setFieldValue('configuration.superpositionPreset', SuperpositionPreset.CUSTOM)
      this.$emit('update:modelValue', value)
    },
    onPresetChange(value: SuperpositionPreset) {
      if (value !== SuperpositionPreset.CUSTOM) {
        this.$emit(
          'update:modelValue',
          generateSuperpositionPresetExpression(value, this.operationStates)
        )
      }
    },
    operationStateLabel,
    operationStateAtomic
  },
  computed: {
    operationStatesOptions(): (DropdownOption & { operationState: OperationState })[] {
      return this.operationStatesDraft.map((value: OperationState, index: number) => {
        return {
          command: index,
          label: `${operationStateToString(value)}`,
          operationState: value
        }
      })
    },
    insertButtons(): { label: string; insertText: string }[] {
      return [
        { label: '+', insertText: '+' },
        { label: '-', insertText: '+' },
        { label: '*', insertText: '*' },
        { label: 'max()', insertText: 'max()' },
        { label: 'abs()', insertText: 'abs()' },
        { label: '√²', insertText: 'sqrt()' },
        { label: '()²', insertText: 'pow(,2)' }
      ]
    }
  }
})
</script>

<template>
  <div class="flex flex-col gap-4">
    <p class="text-gray-400">
      Die Überlagerung definiert, wie die gemeinsame Wirkung der einzelnen Betriebszustände und
      Systeme auf die Medien im Gesamtergebnis berechnet wird:
    </p>

    <p-field
      v-bind="fieldConfig.superpositionPreset"
      name="configuration.superpositionPreset"
      dense
      @change="onPresetChange"
    />

    <p-form-section title="Überlagerung definieren" class="!mt-0">
      <div class="flex flex-col gap-3">
        <!-- Toolbar -->
        <div>
          <el-dropdown
            :hide-on-click="false"
            placement="top-start"
            trigger="click"
            class="mr-4"
            @command="insertAtCursorPosition(operationStateAtomic($event))"
          >
            <el-button size="small">
              Betriebszustand einfügen
              <el-icon class="ml-1"><ChevronUpIcon /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="(item, index) in operationStatesOptions"
                  :key="item.command"
                  :label="item.label"
                  :command="item.command"
                  class="flex !items-center gap-1 !py-2"
                >
                  <SystemTag :system="item.operationState.system" type="small">
                    {{ operationStateLabel(index) }}
                  </SystemTag>
                  <div class="pl-1 leading-[1.05em]">
                    <div class="text-xs font-semibold">
                      {{ systemName(item.operationState.system) }}
                    </div>
                    <small class="text-gray-400">{{ item.label }}</small>
                  </div>
                  &nbsp;
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <el-button
            v-for="insertButton in insertButtons"
            :key="insertButton.insertText"
            size="small"
            @click="insertAtCursorPosition(insertButton.insertText)"
          >
            {{ insertButton.label }}
          </el-button>
        </div>

        <div>
          <!--
      Codemirror Vue Wrapper
      See: https://github.com/surmon-china/vue-codemirror?tab=readme-ov-file#component-props
    -->
          <Codemirror
            :key="key"
            :model-value="modelValue"
            placeholder=""
            :style="{ height: '120px', border: 'var(--el-border)' }"
            :autofocus="true"
            :indent-with-tab="true"
            :tab-size="2"
            :extensions="extensions"
            @keydown.esc.stop
            @update:model-value="onEditorChange"
            @ready="handleReady"
          />
          <div class="flex pt-3 justify-between">
            <div class="text-gray-400 text-xs">
              <span class="key">Strg</span>
              +
              <span class="key">Leertaste</span>
              für Code-Vervollständigung
            </div>
            <div class="text-xs">
              <span v-if="superpositionField.meta.valid" class="font-semibold text-success-500">
                Die Formel ist gültig.
              </span>
              <span v-else class="font-semibold text-error-500">Die Formel enthält Fehler.</span>
            </div>
          </div>
        </div>
      </div>
      <div>{{}}</div>
    </p-form-section>
  </div>
</template>

<style scoped lang="css">
:deep(.cm-focused) {
  outline: none;
}
.key {
  @apply border bg-gray-100 rounded px-1;
}
</style>
