<script lang="ts">
import { useProject } from '@/composables/useProject'
import { defineComponent } from 'vue'
import DetailPanel from '@/layouts/pages/DetailPanel.vue'
import { SpanId } from '@/model'
import { Span } from '@gridside/hsb-api'
import SpanForm from '@/views/project/spans/SpanForm.vue'
import { ElNotification } from 'element-plus'
import { RouteLocationRaw } from 'vue-router'
import { useOverheadLine } from '@/composables/useOverheadLine'
import { RouteParams } from '@/router/routeParams'

export default defineComponent({
  name: 'SpanDetail.vue',
  components: { SpanForm, DetailPanel },

  setup() {
    const { overheadLineRecord, overheadLineStore } = useOverheadLine()
    const { projectId } = useProject()
    return { projectId, overheadLineRecord, overheadLineStore }
  },

  data: () => ({
    saving: false
  }),

  computed: {
    spanId() {
      return this.$route.params[RouteParams.SpanId] as string
    },
    create(): boolean {
      return !this.spanId
    },
    selectedItems(): Span[] {
      return this.overheadLineStore.spansSelection
        .map((id: SpanId) => this.overheadLineStore.findSpanById(id))
        .filter((item): item is Span => item !== undefined)
    },
    title(): string {
      const selectionCount = this.selectedItems.length
      if (selectionCount > 1) {
        return `${selectionCount} Spannfelder bearbeiten`
      } else {
        return 'Spannfeld bearbeiten'
      }
    }
  },

  methods: {
    onClose() {
      this.overheadLineStore.spansSelection = []
      this.$router.push(
        (this.$route.meta.returnRoute as RouteLocationRaw) || { name: 'overhead-line-spans' }
      )
    },
    async onSave(items: Span[]) {
      this.saving = true
      try {
        for (const item of items) {
          await this.overheadLineStore.spanSave(item)
        }
        ElNotification.success('Daten wurden erfolgreich gespeichert.')
      } finally {
        this.saving = false
      }
    },

    async onSaveChanges(changeset: Partial<Span>) {
      this.saving = true
      try {
        const spanIds = this.selectedItems.map((item) => item.id)
        await this.overheadLineStore.spanSaveBatch(spanIds, changeset)
        ElNotification.success('Daten wurden erfolgreich gespeichert.')
      } finally {
        this.saving = false
      }
    }
  }
})
</script>

<template>
  <DetailPanel :title="title" data-test="span-detail" @close="onClose">
    <SpanForm
      :items="selectedItems"
      :saving="saving"
      @cancel="onClose"
      @submit="(items: Span[]) => create && onSave(items)"
      @submit:changes="(changes: Partial<Span>) => !create && onSaveChanges(changes)"
    />
  </DetailPanel>
</template>

<style scoped lang="css"></style>
