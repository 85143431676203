import { z } from 'zod'
import { OverheadLineExport } from '@gridside/hsb-api'
import { SpanSchema } from '@/config/schemas/span-schema'
import { TowerBaseSchema } from '@/config/schemas/tower-schema'

export const OverheadLineSchemaBase = z.object({
  description: z.string().nullable().optional(),
  name: z.string(),
  id: z.string(),
  length: z.number().optional(),
  project: z.string(),
  towerCount: z.number(),
  systemsUsed: z.array(z.string()).optional()
})

export const OverheadLineExportSchema: z.ZodType<OverheadLineExport> =
  OverheadLineSchemaBase.extend({
    data: z.object({ span: z.array(SpanSchema), tower: z.array(TowerBaseSchema) })
  })
