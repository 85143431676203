<script lang="ts">
import { useProject } from '@/composables/useProject'
import { ProjectId } from '@/model'
import { useProjectStore } from '@/stores/project'
import { Project } from '@gridside/hsb-api'
import { defineComponent, PropType } from 'vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/de'

export default defineComponent({
  name: 'SelectProjectStep',
  props: {
    modelValue: {
      type: String as PropType<ProjectId>
    }
  },
  data: () => ({
    searchString: null as string | null
  }),
  setup() {
    dayjs.locale('de')
    dayjs.extend(relativeTime)
    dayjs.extend(utc)

    return {
      project: useProject().project,
      projectStore: useProjectStore()
    }
  },

  mounted() {
    this.$emit('update:modelValue', undefined)
  },

  computed: {
    filteredProjects() {
      let filteredProjects = this.projectStore.projects.filter(
        (project) => project.id !== this.project?.id
      )
      if (this.searchString && this.searchString.trim() !== '') {
        filteredProjects = this.projectStore.projects.filter(
          (project) =>
            project.name.toLowerCase().includes(this.searchString!.toLowerCase()) ||
            project.customer?.toLowerCase().includes(this.searchString!.toLowerCase())
        )
      }
      return filteredProjects.sort((a, b) => {
        if (a.crs === this.project?.crs && b.crs !== this.project?.crs) {
          return -1
        }
        if (a.crs !== this.project?.crs && b.crs === this.project?.crs) {
          return 1
        }
        return b.updatedAt.getTime() - a.updatedAt.getTime()
      })
    }
  },

  methods: {
    formatRelativeDate(date: Date) {
      return dayjs(date).utc(true).fromNow()
    },
    onCurrentChange(project: Project) {
      if (project.crs === this.project?.crs) {
        this.$emit('update:modelValue', project.id)
      } else {
        this.$emit('update:modelValue', undefined)
      }
    }
  }
})
</script>

<template>
  <div class="flex flex-col space-y-4 font-normal text-gray-800">
    <div>
      <el-input
        autofocus
        placeholder="Suche"
        suffix-icon="SearchIcon"
        clearable
        v-model="searchString"
        @change="$emit('update:modelValue', undefined)"
      />
    </div>
    <div class="text-sm text-gray-400 text-center">
      {{ $t('overheadLine.importFromProject.projectsFound', { count: filteredProjects.length }) }}:
    </div>
    <div class="border rounded">
      <el-table
        size="small"
        height="calc(80vh - 200px)"
        :data="filteredProjects"
        highlight-current-row
        empty-text="Kein Projekt für den Suchtext gefunden"
        @current-change="onCurrentChange"
      >
        <el-table-column prop="name" label="Projektname" width="auto" v-slot="{ row }">
          <span :class="row.crs === project?.crs ? 'font-semibold' : 'text-gray-400'">
            {{ row.name }}
          </span>
        </el-table-column>
        <el-table-column prop="crs" label="Koordinatensystem" width="200" v-slot="{ row }">
          <div class="flex items-center space-x-2">
            <div>{{ row.crs }}</div>
            <el-icon
              v-if="row.crs !== project?.crs"
              title="Koordinatensystem unterscheidet sich vom aktuellen Projekt"
            >
              <WarningIcon class="w-4 text-warning-500" />
            </el-icon>
          </div>
        </el-table-column>
        <el-table-column prop="updatedAt" label="zuletzt geändert" width="200" v-slot="{ row }">
          {{ formatRelativeDate(row.updatedAt) }}
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<style scoped lang="css">
:deep(.el-table__row),
:deep(.el-table__header tr th) {
  @apply text-sm;
}

:deep(.el-table__row:hover) {
  cursor: pointer;
}
</style>
