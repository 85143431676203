import useMapInteraction from '@/components/map/composables/useMapInteraction'
import { MapMode, useMapMode } from '@/components/map/composables/useMapMode'
import { useMapSelection } from '@/components/map/composables/useMapSelection'
import { deleteDraftFeatures } from '@/components/map/layer/source/functions'
import { HitTolerance, SourceLayerContext } from '@/components/map/layer/util'
import { featureIsDraft, featureOfType, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import { sourceEditStyle } from '@/components/map/style/source'
import { SpanId, TowerId } from '@/model'
import { RouteParams } from '@/router/routeParams'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { click, platformModifierKeyOnly, shiftKeyOnly } from 'ol/events/condition'
import { Select } from 'ol/interaction'
import { watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export function useSourceSelect(ctx: SourceLayerContext) {
  const mapMode = useMapMode()
  const overheadLineStore = useOverheadLineStore()
  const router = useRouter()
  const { registerSelectInteraction, selectedFeatures, select } = useMapSelection()

  const selectInteraction = new Select({
    condition: click,
    style: sourceEditStyle,
    layers: [ctx.layer],
    hitTolerance: HitTolerance,
    toggleCondition: (e) => platformModifierKeyOnly(e) || shiftKeyOnly(e)
  })

  registerSelectInteraction(selectInteraction)
  useMapInteraction(selectInteraction)

  // only activate on edit mode
  watch(
    mapMode,
    () => {
      selectInteraction.setActive(mapMode.value === MapMode.EDIT)
    },
    { immediate: true }
  )

  // initial selections
  ctx.layer.on('change:source', () => {
    const entityIds = [RouteParams.OverheadLineId, RouteParams.SpanId, RouteParams.TowerId]
    const route = useRoute()
    if (!route) {
      console.warn('useSourceSelect() - route is not defined')
      return
    }
    const routeParams = route.params
    for (const entityParam of entityIds) {
      if (Object.keys(routeParams).includes(entityParam)) {
        const id = routeParams[entityParam]
        const feature = ctx.layer
          .getSource()
          ?.getFeatures()
          .find((feature) => feature.getId() === id)

        if (feature) {
          select(feature)
          selectInteraction.getFeatures().push(feature)
        }
        break
      }
    }
  })

  // dispatch selection changes to their respective routes
  watch(selectedFeatures, () => {
    const projectId = router.currentRoute.value.params[RouteParams.ProjectId]
    if (!projectId) {
      return
    }

    const { selectedTowers, selectedSpans, selectedOverheadLines } = ctx

    // handle Towers
    if (selectedTowers.value.length > 0) {
      const draft = overheadLineStore.towerDraft
      if (draft?.id) {
        overheadLineStore.towersSelection = [draft.id]
        if (overheadLineStore.towerDraft?.overheadLine) {
          router.push({
            name: 'project-map-tower-create'
          })
        }
      } else {
        overheadLineStore.towersSelection = selectedTowers.value.map(
          (feature) => feature.getId() as TowerId
        )
        router.push({
          name: 'project-map-tower-edit',
          params: {
            towerId: selectedTowers.value[0].getId()
          }
        })
      }
    } else if (selectedSpans.value.length > 0) {
      // handle Spans
      overheadLineStore.spansSelection = selectedSpans.value.map(
        (feature) => feature.getId() as SpanId
      )
      router.push({
        name: 'project-map-span-edit',
        params: { spanId: selectedSpans.value[0].getId() }
      })
    } else if (selectedOverheadLines.value.length > 0) {
      router.push({
        name: 'project-map-overheadLine-edit',
        params: { overheadLineId: selectedOverheadLines.value[0].getId() }
      })
    } else {
      overheadLineStore.towersSelection = []
      overheadLineStore.spansSelection = []
    }
  })

  // Delete all draft features when another or no item is selected
  watch(selectedFeatures, () => {
    const selectedDraft = !!selectedFeatures.value.find(featureIsDraft)
    const selectedOtherType = !!selectedFeatures.value.find(
      (f) =>
        !featureOfType(f, [
          FeatureTypeEnum.span,
          FeatureTypeEnum.tower,
          FeatureTypeEnum.overheadLine
        ])
    )
    if (!selectedDraft || selectedOtherType) {
      deleteDraftFeatures(ctx)
    }
  })

  return { selectInteraction }
}
