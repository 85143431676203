import { MediaLayerContext } from '@/components/map/layer/util'
import { FeaturePropEnum } from '@/components/map/LayerItemConfig'
import { watch } from 'vue'

export const useMediaGroupLayerOptions = (ctx: MediaLayerContext) => {
  const { visibleFeatures } = ctx

  watch(visibleFeatures, () => syncLayerOptions(ctx), { immediate: true })
  ctx.layer.on('change:source', () => syncLayerOptions(ctx))
}

export function syncLayerOptions(ctx: MediaLayerContext) {
  const { layer, visibleFeatures } = ctx

  const source = layer.getSource()
  const visibleMediaGroups = visibleFeatures.value
  source?.getFeatures().forEach((feature) => {
    feature.set(
      FeaturePropEnum.hidden,
      !(visibleMediaGroups === undefined || visibleMediaGroups.includes(feature.getId() as string))
    )
  })
}
