import { NumberFieldConfig, TextFieldConfig } from '@prionect/ui/dist/types/components/form/types'

const fields = {
  name: {
    name: 'name',
    label: 'Name',
    required: true
  } as TextFieldConfig,
  alpha: {
    name: 'alpha',
    label: 'Temperaturkoeffizient',
    type: 'number',
    min: 0,
    unit: '1/K',
    required: true
  } as NumberFieldConfig,
  coatingResistance: {
    name: 'coatingResistance',
    label: 'Umhüllungswiderstand ru',
    type: 'number',
    min: 0,
    step: 0.1,
    unit: 'Ωm²',
    required: true
  } as NumberFieldConfig,
  coatingThickness: {
    name: 'coatingThickness',
    label: 'Dicke der Umhüllung',
    type: 'number',
    min: 0,
    step: 0.1,
    unit: 'm',
    required: true
  } as NumberFieldConfig,
  diameter: {
    name: 'diameter',
    label: 'Außendurchmesser Rohr',
    type: 'number',
    step: 0.1,
    unit: 'm',
    required: true
  } as NumberFieldConfig,
  epsR: {
    name: 'epsR',
    label: 'Relative Permittivität Umhüllung ɛr',
    type: 'number',
    min: 0,
    step: 0.1,
    required: true
  } as NumberFieldConfig,
  materialResistivity: {
    name: 'materialResistivity',
    label: 'Spezifischer Widerstand Rohr ρ',
    type: 'number',
    min: 0,
    step: 0.1,
    unit: 'Ωm',
    required: true
  } as NumberFieldConfig,
  muR: {
    name: 'muR',
    label: 'Relative Permeabilität µr',
    type: 'number',
    min: 0,
    step: 0.1,
    required: true
  } as NumberFieldConfig
} as const

export default fields
