import { SourceLayerContext } from '@/components/map/layer/util'
import { featureOfType, FeaturePropEnum, FeatureTypeEnum } from '@/components/map/LayerItemConfig'
import { watch } from 'vue'

export function useSourceLayerOptions(ctx: SourceLayerContext) {
  const { showSpans, showTowers, visibleFeatures } = ctx
  const syncLayerOptionsWithContext = () => syncLayerOptions(ctx)

  /**
   * Show or hide towers and spans depending on layer options
   */
  watch(showTowers, syncLayerOptionsWithContext)
  watch(showSpans, syncLayerOptionsWithContext)
  watch(visibleFeatures, syncLayerOptionsWithContext)

  ctx.layer.on('change:source', syncLayerOptionsWithContext)
}

export function syncLayerOptions(ctx: SourceLayerContext) {
  const { layer, showSpans, showTowers, visibleFeatures } = ctx

  const source = layer.getSource()

  source?.getFeatures().forEach((feature) => {
    const visibleOverheadLines = visibleFeatures.value
    const showAllOverheadLines = visibleOverheadLines === undefined

    // decide whether to display overhead lines
    if (featureOfType(feature, FeatureTypeEnum.overheadLine)) {
      const showOverheadLine =
        (showAllOverheadLines || visibleOverheadLines.includes(feature.getId() as string)) &&
        showSpans.value
      feature.set(FeaturePropEnum.hidden, !showOverheadLine)
    }

    // true if this feature belongs to an overhead line that is visible
    const belongsToVisibleOverheadLine =
      showAllOverheadLines || visibleOverheadLines.includes(feature.get('overheadLine'))

    // display towers?
    if (featureOfType(feature, FeatureTypeEnum.tower)) {
      feature.set(FeaturePropEnum.hidden, !(showTowers.value && belongsToVisibleOverheadLine))
    }

    // display spans?
    if (featureOfType(feature, FeatureTypeEnum.span)) {
      feature.set(FeaturePropEnum.hidden, !(showSpans.value && belongsToVisibleOverheadLine))
    }
  })
}
