import { computed, ref, watch } from 'vue'
import { Feature } from 'ol'
import { GeoJSON } from 'ol/format'
import { Vector as VectorSource } from 'ol/source'
import { SourceLayerContext } from '@/components/map/layer/util'
import { useProject } from '@/composables/useProject'
import { useOverheadLineStore } from '@/stores/overhead-lines'
import { syncLayerOptions } from './useSourceLayerOptions'

/**
 * Generates a GeoJSON with both towers and spans
 */
export function useSourceGeoJSON(ctx: SourceLayerContext) {
  const { project } = useProject()

  const source = ref<VectorSource>()
  const overheadLineStore = useOverheadLineStore()

  const geoJSON = computed(() => {
    const { overheadLineGeoJSON, towersGeoJSON, spansGeoJSON } = overheadLineStore
    return {
      ...overheadLineGeoJSON,
      features: [
        ...overheadLineGeoJSON.features,
        ...towersGeoJSON.features,
        ...spansGeoJSON.features
      ]
    }
  })

  watch(
    geoJSON,
    () => {
      if (!project.value) {
        return
      }
      const geoJsonOptions = {
        dataProjection: project.value.crs,
        featureProjection: ctx.map.getView().getProjection()
      }

      // create or update source
      if (!source.value) {
        source.value = new VectorSource({
          features: new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
        })
      } else {
        const features = new GeoJSON(geoJsonOptions).readFeatures(geoJSON.value) as Feature[]
        source.value.clear()
        source.value.addFeatures(features)

        // restore selection despite changed source
        const selectedIds = ctx.selectedFeatures.value.map((feature) => feature.getId())

        const selectInteractionFeatures = ctx.selectInteraction?.getFeatures()
        if (selectInteractionFeatures) {
          features.forEach((feature) => {
            if (selectedIds.includes(feature.getId())) {
              const previousFeature = selectInteractionFeatures
                .getArray()
                .find((f) => f.getId() === feature.getId())
              previousFeature && selectInteractionFeatures.remove(previousFeature)
              selectInteractionFeatures.push(feature)
            }
          })

          overheadLineStore.towersSelection = selectInteractionFeatures
            .getArray()
            .map((feature) => feature.getId() as string)
        }

        // Sync with layer options (i.e. to restore feature visibility)
        syncLayerOptions(ctx)
      }
    },
    { immediate: true }
  )

  // set the updated source as layer source
  watch(source, () => ctx.layer.setSource(source.value || null), { immediate: true })

  return { geoJSON, source }
}
