<script lang="ts">
import { HsbApi } from '@/api'
import PreviewMap from '@/components/map/PreviewMap.vue'
import { useProject } from '@/composables/useProject'
import { OverheadLineId, ProjectId } from '@/model'
import { useProjectStore } from '@/stores/project'
import { OverheadLine } from '@gridside/hsb-api'
import { FeatureCollection, GeoJSON } from 'geojson'

import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'SelectOverheadLinesStep',
  components: { PreviewMap },
  props: {
    modelValue: {
      type: Array as PropType<OverheadLineId[]>,
      default: () => []
    },
    project: {
      type: String as PropType<ProjectId>,
      required: true
    }
  },

  data: () => ({
    loading: false,
    geoJson: undefined as FeatureCollection | undefined,
    overheadLines: [] as OverheadLine[]
  }),

  setup() {
    return {
      currentProject: useProject().project,
      projectStore: useProjectStore()
    }
  },

  computed: {
    filteredGeoJson() {
      const filteredFeatures = this.geoJson?.features.filter((f) =>
        this.selectedOverheadLines.includes(f.properties?.overheadLine)
      )

      if (!filteredFeatures || filteredFeatures?.length === 0) {
        return undefined
      }

      return {
        ...this.geoJson,
        features: filteredFeatures
      }
    },

    selectedProject() {
      return this.projectStore.projectsById[this.project]
    },
    selectedOverheadLines: {
      get() {
        return this.modelValue
      },
      set(v: OverheadLineId[]) {
        this.$emit('update:modelValue', v)
      }
    }
  },

  mounted() {
    this.loading = true
    this.loadData()
  },

  methods: {
    async loadData() {
      this.overheadLines = (await HsbApi.overheadLines.getOverheadLines(this.project)).results
      // select all overhead lines by default
      this.selectedOverheadLines = this.overheadLines.map((item) => item.id)

      await this.buildGeoJson()
      this.loading = false
    },

    async buildGeoJson() {
      const geoJson: GeoJSON = { type: 'FeatureCollection', features: [] }
      for (const overheadLine of this.overheadLines) {
        const towers = (await HsbApi.towers.getTowersByOverheadline(this.project, overheadLine.id))
          .results
        towers.forEach((tower) => {
          geoJson.features.push({
            type: 'Feature',
            geometry: { type: 'Point', coordinates: [tower.x, tower.y] },
            properties: { id: tower.id, _type: 'tower', overheadLine: overheadLine.id }
          })
        })
        geoJson.features.push({
          type: 'Feature',
          geometry: { type: 'LineString', coordinates: towers.map((tower) => [tower.x, tower.y]) },
          properties: { _type: 'overheadLine', overheadLine: overheadLine.id }
        })
      }
      this.geoJson = geoJson
    }
  }
})
</script>

<template>
  <div style="height: calc(80vh - 200px)" v-loading="loading">
    <div class="flex h-full space-x-8" v-if="!loading">
      <div class="flex-1 overflow-hidden flex flex-col">
        <h3 class="font-semibold mb-2">Freileitung(en) zum Import auswählen:</h3>
        <el-alert v-if="overheadLines.length === 0" show-icon type="warning" class="!mt-4">
          Das Projekt
          <em>{{ selectedProject?.name }}</em>
          enthält keine Freileitungen.
        </el-alert>
        <div v-else class="overflow-y-scroll flex-1">
          <el-checkbox-group v-model="selectedOverheadLines">
            <el-checkbox
              v-for="overheadLine in overheadLines"
              :label="overheadLine.name"
              :value="overheadLine.id"
              class="!min-h-16 !h-auto !items-start border p-4 mb-2 rounded w-full !leading-relaxed overflow-hidden"
            >
              <div class="leading-relaxed -mt-1.5">
                <div>{{ overheadLine.name }}</div>
                <div v-if="overheadLine.description" class="text-normal opacity-70">
                  {{ overheadLine.description }}
                </div>
                <div v-if="overheadLine.operator" class="text-normal opacity-70">
                  {{ overheadLine.operator }}
                </div>
                <div class="text-normal opacity-70">
                  {{ $t('tower.count', { count: overheadLine.towerCount }) }},
                  {{ $n(overheadLine.length || 0, { maximumFractionDigits: 1 }) }} km,
                  {{ $t('system.count', { count: overheadLine.systemsUsed?.length || 0 }) }}
                </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="border flex-1">
        <preview-map
          v-if="selectedProject && filteredGeoJson"
          class="h-full flex-1"
          :crs="selectedProject.crs"
          :geojson="filteredGeoJson as GeoJSON"
        />
        <div v-else class="flex items-center justify-center h-full text-sm text-gray-500">
          Keine Freileitung ausgewählt
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="css">
:deep(.el-checkbox) {
  margin-right: 0;
}
</style>
